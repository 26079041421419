export const breadcrumbList = [
  {
    name: '设置',
    path: ''
  },
  {
    name: '权限',
    path: ''
  },
  {
    name: '权限设置',
    path: ''
  },
  {
    name: '新建角色',
    path: ''
  }
]
