<template>
  <div class="add-form">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="ruleForm"
      :size="formSize"
    >
      <el-form-item label="角色名称：" prop="name">
        <div class="role-name">
          <el-input></el-input>
        </div>
      </el-form-item>

      <el-form-item label="角色归属：" prop="name">
        <div class="role">
          <div class="top">
            <el-radio v-model="radio1" label="1" size="large">总部角色</el-radio>
            <el-radio v-model="radio1" label="2" size="large">门店角色</el-radio>
          </div>
          <div class="tips">注：门店角色仅能查看及操作门店相关数据</div>
        </div>
      </el-form-item>

      <el-form-item label="设置权限：" prop="name">
        <div class="authority">
          <div class="titles">
            <div>
              <el-checkbox v-model="checked1" label="模块" size="large"></el-checkbox>
            </div>
            <div>
              <el-checkbox v-model="checked1" label="页面" size="large"></el-checkbox>
            </div>
            <div>
              <el-checkbox v-model="checked1" label="业务操作" size="large"></el-checkbox>
            </div>
          </div>
          <div class="authority-list">
            <div class="authority-item">
              <div class="left">
                <el-checkbox v-model="checked1" label="门店管理" size="large"></el-checkbox>
              </div>
              <div class="right">
                <div class="authorit-detail">
                  <div class="detail-left">
                    <el-checkbox v-model="checked1" label="门店列表" size="large"></el-checkbox>
                  </div>
                  <div class="detail-right">
                    <div>
                      <el-checkbox v-model="checked1" label="搜索门店数据" size="large"></el-checkbox>
                    </div>
                    <div>
                      <el-checkbox v-model="checked1" label="启用/停用门店" size="large"></el-checkbox>
                    </div>
                  </div>
                </div>

                <div class="authorit-detail">
                  <div class="detail-left">
                    <el-checkbox v-model="checked1" label="新增门店" size="large"></el-checkbox>
                  </div>
                  <div class="detail-right">
                    <div>
                      <el-checkbox v-model="checked1" label="新增门店数据" size="large"></el-checkbox>
                    </div>
                  </div>
                </div>

                <div class="authorit-detail">
                  <div class="detail-left">
                    <el-checkbox v-model="checked1" label="修改门店" size="large"></el-checkbox>
                  </div>
                  <div class="detail-right">
                    <div>
                      <el-checkbox v-model="checked1" label="修改门店数据" size="large"></el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="authority-item">
              <div class="left">
                <el-checkbox v-model="checked1" label="门店管理" size="large"></el-checkbox>
              </div>
              <div class="right">
                <div class="authorit-detail">
                  <div class="detail-left">
                    <el-checkbox v-model="checked1" label="预约列表" size="large"></el-checkbox>
                  </div>
                  <div class="detail-right">
                    <div>
                      <el-checkbox v-model="checked1" label="添加预约" size="large"></el-checkbox>
                    </div>
                    <div>
                      <el-checkbox v-model="checked1" label="取消预约" size="large"></el-checkbox>
                    </div>
                    <div>
                      <el-checkbox v-model="checked1" label="变更预约信息（时间/服务/房间）" size="large"></el-checkbox>
                    </div>
                    <div>
                      <el-checkbox v-model="checked1" label="确认预约" size="large"></el-checkbox>
                    </div>
                  </div>
                </div>

                <div class="authorit-detail">
                  <div class="detail-left">
                    <el-checkbox v-model="checked1" label="预约看板-理疗师维度" size="large"></el-checkbox>
                  </div>
                  <div class="detail-right">
                    <div>
                      <el-checkbox v-model="checked1" label="添加占用" size="large"></el-checkbox>
                    </div>
                    <div>
                      <el-checkbox v-model="checked1" label="添加预约" size="large"></el-checkbox>
                    </div>
                    <div>
                      <el-checkbox v-model="checked1" label="取消预约" size="large"></el-checkbox>
                    </div>
                    <div>
                      <el-checkbox v-model="checked1" label="变更预约信息（时间/服务/房间）" size="large"></el-checkbox>
                    </div>
                    <div>
                      <el-checkbox v-model="checked1" label="确认预约" size="large"></el-checkbox>
                    </div>
                  </div>
                </div>

                <div class="authorit-detail">
                  <div class="detail-left">
                    <el-checkbox v-model="checked1" label="预约看板-房间维度" size="large"></el-checkbox>
                  </div>
                  <div class="detail-right">
                    <div>
                      <el-checkbox v-model="checked1" label="添加占用" size="large"></el-checkbox>
                    </div>
                    <div>
                      <el-checkbox v-model="checked1" label="添加预约" size="large"></el-checkbox>
                    </div>
                    <div>
                      <el-checkbox v-model="checked1" label="取消预约" size="large"></el-checkbox>
                    </div>
                    <div>
                      <el-checkbox v-model="checked1" label="变更预约信息（时间/服务/房间）" size="large"></el-checkbox>
                    </div>
                    <div>
                      <el-checkbox v-model="checked1" label="确认预约" size="large"></el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item >
        <div class="save-wrap">
          <el-button class="save-btn" type="primary">保存角色</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.add-form {
  .ruleForm {
    .save-wrap{
      max-width: 800px;
      display: flex;
      justify-content: flex-end;
       margin-top: 20px;
    }
    .role-name {

      &:deep(.el-input) {
        max-width: 240px;
      }
    }
    .role {
      .tips {
        color: rgba(166, 166, 166, 1);
        font-size: 12px;
      }
    }
    .authority {
      max-width: 800px;
      .authority-list {
        .authority-item {
          display: flex;
          border-bottom: 1px solid #f0f0f0;
          padding: 20px 10px;
          .left {
            flex: 1;
            color: rgba(80, 80, 80, 1);
            font-size: 14px;
            &:deep(span) {
              font-weight: bold;
            }
          }
          .right {
            flex: 2;
            .authorit-detail {
              display: flex;
              border-bottom: 1px solid var(--borderColor);
              .detail-left {
                flex: 1;
              }
              .detail-right {
                flex: 1;
              }
            }
          }
        }
      }
      .titles {
        display: flex;
        background-color: rgba(239, 239, 239, 1);
        font-size: 14px;
        padding: 0 10px;
        div {
          flex: 1;
        }
      }
    }
  }
}
</style>




